import * as React from "react"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { Content } from "@prismicio/client"
import { PrismicNextLink } from "@prismicio/next"

import { GetSingleDealerReturnType } from "@/lib/server-api/dealers-types"
import { cn } from "@/lib/utils"
import { useLockBody } from "@/hooks/use-lock-body"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion"
import { Button } from "../ui/button"

interface PropsIf {
  settings: Content.SettingsDocument
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
  dealerOverride?: GetSingleDealerReturnType
  partition?: boolean
}

export function MobileNavMenu({
  settings,
  setShowMobileMenu,
  dealerOverride,
  partition,
}: PropsIf) {
  useLockBody()
  const pathname = usePathname()

  return (
    <div
      className={cn(
        `bg-white fixed inset-0 top-[80px] max-h-[calc(100dvh-80px)] p-2 border-t border-carbon-100 z-50 h-fit overflow-auto shadow rounded-sm animate-in slide-in-from-bottom-10 fade-in lg:hidden text-carbon-400`
      )}
    >
      {settings.data.slices1.map((item) => {
        if (item.primary.hide_for_partitioned_dealers && partition) {
          return null
        }
        if (item.primary.show_only_partitioned_dealers && !partition) {
          return null
        }
        return (
          <React.Fragment key={item.id}>
            <Accordion type="single" collapsible className="w-full">
              {item.items.length > 0 ? (
                <AccordionItem value={item.id ?? ""} key={item.id}>
                  <AccordionTrigger className="pr-4">
                    <Button variant={"link"}>{item.primary.label}</Button>
                  </AccordionTrigger>
                  <AccordionContent>
                    <ul className="ml-6">
                      {item.items.map((subItem, i) => {
                        return (
                          <li key={item.id + "subItem" + i}>
                            <PrismicNextLink
                              onClick={() =>
                                setShowMobileMenu((b: boolean) => !b)
                              }
                              className={cn(
                                "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                              )}
                              field={subItem.sub_nav_link}
                            >
                              <h4 className="text-sm font-medium leading-none">
                                {subItem.sub_nav_label}
                              </h4>
                              <p className="text-sm leading-snug line-clamp-2 text-muted-foreground">
                                {subItem.sub_nav_copy}
                              </p>
                            </PrismicNextLink>
                          </li>
                        )
                      })}
                    </ul>
                  </AccordionContent>
                </AccordionItem>
              ) : (
                <PrismicNextLink
                  field={item.primary.link}
                  onClick={() => setShowMobileMenu((b: boolean) => !b)}
                  className={cn(
                    "flex flex-1 items-center justify-between py-4 font-medium transition-all cursor-pointer"
                  )}
                >
                  <Button
                    className={cn(
                      (item.primary.link as any).url.includes(pathname) &&
                        pathname !== "/"
                        ? "font-semibold text-carbon-900"
                        : ""
                    )}
                    variant={"link"}
                  >
                    {item.primary.label}
                  </Button>
                </PrismicNextLink>
              )}
            </Accordion>
          </React.Fragment>
        )
      })}
      {!partition ? (
        <Button className="ml-4 mb-4 mt-2" asChild variant={"default"}>
          <Link href={"/program"}>Become a Pro</Link>
        </Button>
      ) : (
        <>
          {dealerOverride && (
            <Button className="ml-4 mb-4 mt-2" asChild variant={"default"}>
              <Link href={`/professionals/${dealerOverride?.geo_area}`}>
                My {dealerOverride?.locale} Pro
              </Link>
            </Button>
          )}
        </>
      )}
    </div>
  )
}
