"use client"

import { useState } from "react"
import { Content } from "@prismicio/client"
import { Menu, X } from "lucide-react"

import { GetSingleDealerReturnType } from "@/lib/server-api/dealers-types"

import { Button } from "../ui/button"
import { MobileNavMenu } from "./MobileNavMenu"

interface PropsIf {
  settings: Content.SettingsDocument
  dealerOverride?: GetSingleDealerReturnType
  partition?: boolean
}

export default function MobileNav({
  settings,
  dealerOverride,
  partition,
}: PropsIf) {
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false)

  return (
    <div className="lg:hidden">
      <Button
        size={"icon"}
        variant={"outline"}
        onClick={() => setShowMobileMenu(!showMobileMenu)}
        aria-label="Mobile navigation"
      >
        {showMobileMenu ? (
          <X className="size-4" />
        ) : (
          <Menu className="size-4" />
        )}
      </Button>
      {showMobileMenu && (
        <MobileNavMenu
          settings={settings}
          setShowMobileMenu={setShowMobileMenu}
          dealerOverride={dealerOverride}
          partition={partition}
        />
      )}
    </div>
  )
}
